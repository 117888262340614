import axios from "@/axios";

async function populateBanks() {
  if (!sessionStorage.getItem("_banks")) {
    const r = await axios.get("api/v1/banks");
    sessionStorage.setItem("_banks", JSON.stringify(r.data.data.bank) || "");
  }
}

async function populateServices() {
  if (!sessionStorage.getItem("_services")) {
    const r = await axios.get("api/v1/services");
    sessionStorage.setItem(
      "_services",
      JSON.stringify(r.data.data.services) || ""
    );
  }
}

async function populateCategories() {
  if (!sessionStorage.getItem("_serviceCategories")) {
    const r = await axios.get("api/v1/servicesCategory");
    sessionStorage.setItem(
      "_serviceCategories",
      JSON.stringify(
        r.data.data.servicesCategory.filter((a) =>
          [
            "MOBILE_TOPUP",
            "UTILITY_PAYMENT",
            "INTERNET_BILLS",
            "LEARN",
            "BUS_TICKET",
            "HEALTH_CARE",
            "INSURANCE",
            "MOBILE_DATA_PACK",
            "FLIGHT",
            "MOVIES",
            "CREDITCARD",
            "CABLE_CAR",
          ].includes(a.slug)
        )
      ) || ""
    );
  }
}

let tryOneTime = true;

export default {
  async banks() {
    await populateBanks();
    return JSON.parse(sessionStorage.getItem("_banks")) || [];
  },
  async serviceCategories() {
    await populateCategories();
    return JSON.parse(sessionStorage.getItem("_serviceCategories")) || [];
  },
  async serviceIdBySlug(SERVICE_SLUG) {
    await populateServices();
    let service = JSON.parse(sessionStorage.getItem("_services")).find(
      (service) => {
        return service.shortName === SERVICE_SLUG;
      }
    );
    if (service) {
      //returning shortName but need ID??
      //return service.shortName;
      return service._id;
    } else {
      if (tryOneTime) {
        sessionStorage.removeItem("_services");
        tryOneTime = false;
        this.serviceIdBySlug(SERVICE_SLUG);
      }
    }
  },
  async servicesByCategorySlug(CAT_SLUG) {
    await populateServices();
    let services = JSON.parse(sessionStorage.getItem("_services")).filter(
      (sc) => {
        return sc.category.slug === CAT_SLUG;
      }
    );
    if (services) {
      return services;
    } else {
      if (tryOneTime) {
        sessionStorage.removeItem("_services");
        tryOneTime = false;
        this.servicesByCategorySlug(CAT_SLUG);
      }
    }
  },
  async neaOfficeCodes(id) {
    if (!sessionStorage.getItem("_neaOfficeCode")) {
      let office = await axios.get("api/v2/services/nea/officecode");
      if (
        office &&
        office.data &&
        office.data.response &&
        office.data.response.OfficeCodes
      ) {
        sessionStorage.setItem(
          "_neaOfficeCode",
          JSON.stringify(office.data.response.OfficeCodes)
        );
      } else {
        return null;
      }
    }
    return JSON.parse(sessionStorage.getItem("_neaOfficeCode")) || [];
  },
  async nwsOfficeCodes(id) {
    if (!sessionStorage.getItem("_nwsOfficeCode")) {
      let office = await axios.get("api/v2/services/nws/officecode");
      if (
        office &&
        office.data &&
        office.data.response &&
        office.data.response.OfficeCodes
      ) {
        sessionStorage.setItem(
          "_nwsOfficeCode",
          JSON.stringify(office.data.response.OfficeCodes)
        );
      } else {
        return null;
      }
    }
    return JSON.parse(sessionStorage.getItem("_nwsOfficeCode")) || [];
  },

  async upabhoktaOfficeCodes(id) {
    if (!sessionStorage.getItem("_upabhoktaOfficeCodes")) {
      let office = await axios.get("api/v2/services/ubws/officecode");
      if (
        office &&
        office.data &&
        office.data.response &&
        office.data.response.OfficeCodes
      ) {
        sessionStorage.setItem(
          "_upabhoktaOfficeCodes",
          JSON.stringify(office.data.response.OfficeCodes)
        );
      } else {
        return null;
      }
    }
    return JSON.parse(sessionStorage.getItem("_upabhoktaOfficeCodes")) || [];
  },
  async isKycNotVerified() {
    let res = await axios.get("api/v1/kyc");

    function rejectComment() {
      if (
        Object.keys(res.data.data).length != 0 &&
        !res.data.data.verified.status &&
        res.data.data.commentsHistory.length > 0
      )
        return res.data.data.commentsHistory.pop();
      else return "";
    }
    return {
      notFilled: Object.keys(res.data.data).length === 0,
      inProgress:
        Object.keys(res.data.data).length != 0 &&
        !res.data.data.verified.status &&
        res.data.data.commentsHistory.length === 0,
      rejected: rejectComment(),
    };
  },

  async pinNotCreated() {
    let res = await axios.post("api/v1/walletpin/check", { pin: "000000" });
    return res.data.status_code === 404;
  },
};
